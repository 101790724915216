<template>
  <div class="container">
    <div class="box mb-4">
      <div class="columns">
        <div class="column">
          <p class="title">Invoice: {{ invoice.invoice_no }}</p>
          <b-tag
            class="mb-2"
            type="is-danger"
            v-if="invoice.is_overdue && invoice.paid_date === null"
          >
            Overdue</b-tag
          >
          <p class="subtitle">
            <span> Issued on {{ longFormatDate(invoice.invoice_date) }}</span>
            <br />
            <span> Due on {{ longFormatDate(invoice.due_date) }}</span>
          </p>
        </div>
        <div class="column is-4">
          <section v-if="invoice.status == '3'">
            <div class="actions">
              <b-button
                label="Paid"
                type="is-primary is-light"
                icon-pack="ionicons"
                icon-left="fa-solid fa-check"
              />
            </div>
          </section>
          <section v-else>
            <div class="actions" v-if="!invoice.payment_ref">
              <b-upload
                v-model="file"
                class="file-label mr-2 mb-2"
                accept=".pdf,.jpeg,.png,.jpg"
              >
                <a class="button is-light is-fullwidth">
                  <b-icon icon="upload"></b-icon>
                  <span v-if="file.name">{{ truncateName(file.name) }}</span>
                  <span v-else>Upload receipt</span>
                </a>
              </b-upload>
              <b-button
                type="is-primary"
                :loading="isLoading"
                class="mb-2"
                @click="handleSubmit"
              >
                Submit for review</b-button
              >
            </div>
            <div class="actions" v-else>
              <b-button
                label="In Review"
                type="is-warning is-light"
                icon-pack="ionicons"
                icon-left="fa-solid fa-circle-notch"
              />
            </div>
          </section>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <p><strong> Invoice to:</strong></p>
          <p>{{ invoice.employer.name }}</p>
          <p>{{ invoice.employer.address }}</p>
          <p>{{ invoice.employer.city }}</p>
          <p>{{ invoice.employer.email }}</p>
          <p>{{ invoice.employer.phone }}</p>
        </div>
      </div>
      <hr />
      <div class="heading mb-4">invoice line items</div>
      <div class="columns">
        <div class="column">
          <b-field label="Description">
            <b-input v-model="invoice.description" readonly></b-input>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label="Related Job">
            <b-input
              :value="invoice.job ? invoice.job.title : 'None'"
              readonly
            ></b-input>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label="Amount">
            <b-input :value="formatNumber(invoice.amount)" readonly></b-input>
          </b-field>
        </div>
      </div>
      <hr />
      <div class="columns mb-6">
        <div class="column">
          <p class="mb-2"><strong> Payment terms</strong></p>
          <p>
            All our invoices will be due within 5 days of issue of invoice. To
            continue job posting, please ensure that all the invoices are paid
            on time. If you have paid the invoice and its not updated, please
            upload the payment reference and submit for review, we will check
            and update the payment status.
          </p>
        </div>
      </div>
      <div class="columns mb-2">
        <div class="column">
          <p class="mb-2"><strong>Download invoice</strong></p>
          <a
            v-if="invoice.invoice_pdf"
            :href="invoice.invoice_pdf"
            target="_blank"
            class="button is-primary"
            >Download PDF</a
          >
          <p v-else>
            Your invoice is not ready yet. Once it is ready, it will be
            available for download.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      isLoading: false,
      file: {},
    };
  },
  computed: {
    invoice() {
      return this.$store.getters["invoices/getInvoice"](this.$route.params.id);
    },
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-GB");
    },
    longFormatDate(date) {
      return new Date(date).toLocaleDateString("en-GB", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    truncateName(name) {
      return name.length > 20 ? name.substring(0, 20) + "..." : name;
    },
    formatNumber(number) {
      return Number(number).toLocaleString("en-GB");
    },
    checkForm() {
      if (this.file.name) {
        return true;
      } else {
        this.$buefy.toast.open({
          message: "Please upload a payment receipt or reference document",
          type: "is-warning",
          duration: 3000,
        });
        return false;
      }
    },
    async submitForm() {
      const formData = new FormData();
      formData.append("payment_ref", this.file);
      formData.append("status", "2");
      const response = await axios.patch(
        "/api/invoices/" +
          this.invoice.id +
          "/?emp=" +
          this.invoice.employer.id,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        this.$buefy.toast.open({
          message: "Payment receipt uploaded successfully",
          type: "is-success",
          duration: 3000,
        });
        // this.$store.dispatch("invoices/getInvoice", this.$route.params.id);
        this.$store.dispatch(
          "invoices/fetchInvoices",
          this.invoice.employer.id
        );
      } else {
        this.$buefy.toast.open({
          message: "Error uploading payment receipt. Please try again.",
          type: "is-danger",
          duration: 3000,
        });
      }
    },
    handleSubmit() {
      this.isLoading = true;
      if (this.checkForm()) {
        setTimeout(() => {
          this.submitForm().then(() => {
            this.isLoading = false;
          });
        }, 1000);
      } else {
        this.isLoading = false;
      }
    },
  },
  created() {
    //
  },
};
</script>

<style scoped>
/* Align actions class iteems to right in desktop view */
@media only screen and (max-width: 768px) {
  .actions {
    flex-direction: column;
  }
  .file-label {
    width: 100%;
  }
  .file-cta {
    /* width: 100%;
    text-align: center !important;
    align-content: center !important; */
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.blue {
  border: 1px solid blue;
}

.red {
  border: 1px solid red;
}
</style>
